import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaDownload, FaEye } from "react-icons/fa";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import BASE_URL from "../constant";

const UserBookList = () => {
  const [books, setBooks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedBook, setSelectedBook] = useState(null); // To store the selected book for viewing details

  // Fetch books from the server
  useEffect(() => {
    const fetchBooks = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api_user_upload_books.php`); // Replace with your actual API endpoint
        if (response.status >= 200 && response.status < 300) {
          setBooks(response.data.data); // Assuming the response follows your provided structure
        } else {
          alert("Failed to fetch books.");
        }
      } catch (error) {
        console.error(error);
        alert("An error occurred while fetching books.");
      } finally {
        setLoading(false);
      }
    };
    fetchBooks();
  }, []);

  // Function to handle file download (for both PDF and images)
  const handleDownload = async (filePath) => {
    try {
      console.log("Downloading file from:", filePath); // Log the URL for debugging
  
      // Make sure to include the '=' in the URL query parameter
      const response = await fetch(`${BASE_URL}/download_file.php?file=${encodeURIComponent(filePath)}`);
      
      if (!response.ok) {
        throw new Error(`Failed to fetch the file: ${response.status}`);
      }
  
      const blob = await response.blob();
      const fileURL = window.URL.createObjectURL(blob);
  
      const link = document.createElement("a");
      link.href = fileURL;
      link.download = filePath.split("/").pop(); // Extracts the filename from the filePath
      link.click();
  
      window.URL.revokeObjectURL(fileURL);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };
  
  
  

  // Function to handle selecting a book for viewing its details
  const handleViewDetails = (book) => {
    setSelectedBook(book); // Set the selected book for viewing
  };

  // Function to close the view mode
  const handleCloseView = () => {
    setSelectedBook(null); // Reset the selected book to close the view mode
  };

  // Function to download all images as a ZIP
  const downloadAllImagesAsZip = async (book) => {
    const zip = new JSZip();
    const folder = zip.folder(book.name); // Create a folder with the book's name

    // Add images to the ZIP file
    for (let i = 0; i < book.book_pictures.length; i++) {
      const imageUrl = `${BASE_URL}/download_file.php?file=${encodeURIComponent(book.book_pictures[i])}`;
      const response = await axios.get(imageUrl, { responseType: "blob" });
      const imageBlob = response.data;
      folder.file(`image_${i + 1}.jpg`, imageBlob); // Add image with a name like image_1.jpg, image_2.jpg, etc.
    }

    // Generate and download the ZIP file
    zip.generateAsync({ type: "blob" }).then((content) => {
      saveAs(content, `${book.name}_images.zip`); // Save the ZIP file with the book name
    });
  };

  return (
    <div className="py-10 ">
  {loading ? (
    <div className="text-center text-xl text-gray-700">Loading books...</div>
  ) : (
    <div className="max-w-4xl mx-auto bg-white shadow-lg rounded-lg p-6">
      <h2 className="text-2xl font-bold mb-6 text-gray-800 text-center">Book List</h2>

      {/* Table structure with header */}
      <div className="max-h-[400px] overflow-y-auto"> {/* Set a fixed height and enable scrolling */}
        <table className="min-w-full bg-white border border-gray-300">
          <thead>
            <tr>
              <th className="px-6 py-4 text-left font-medium text-gray-700 border-b">Book Title</th>
              <th className="px-6 py-4 text-left font-medium text-gray-700 border-b">Actions</th>
            </tr>
          </thead>
          <tbody>
            {books.length === 0 ? (
              <tr>
                <td colSpan="2" className="text-center py-4 text-gray-700">No books available.</td>
              </tr>
            ) : (
              books.map((book) => (
                <tr key={book.id} className="border-b">
                  <td className="px-6 py-4 text-gray-800">{book.book_name ?? "UnKnown Title"}</td>
                  <td className="px-6 py-4">
                    {/* View Details Button */}
                    <button
                      onClick={() => handleViewDetails(book)}
                      className="mr-4 text-blue-600 hover:text-blue-800"
                    >
                      <FaEye className="mr-2" />
                      View Details
                    </button>

                    {/* Download PDF Button */}
                    <button
                      onClick={() => handleDownload(book.pdf_file)}
                      className="mr-4 text-blue-600 hover:text-blue-800"
                    >
                      <FaDownload className="mr-2" />
                      Download PDF
                    </button>

                    {/* Download All Images as ZIP Button */}
                    <button
                      onClick={() => downloadAllImagesAsZip(book)}
                      className="text-green-600 hover:text-green-800"
                    >
                      <FaDownload className="mr-2" />
                      Download All Images
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  )}

  {/* View Details Modal */}
  {selectedBook && (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center ">
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-2xl w-full ">
      <h3 className="text-2xl font-bold mb-4 text-center">List Book Information</h3>
        <div className="space-y-4 max-h-[600px] overflow-y-auto">
        <h3 className="text-2xl font-bold mb-4 text-center">Book Information</h3>
          <p><strong>Book Title:</strong> {selectedBook.book_name}</p>
          <p><strong>Book Author/Publisher names:</strong> {selectedBook.book_author}</p>
          <p><strong>Book size:</strong> {selectedBook.book_size}</p>
          <p><strong>Book Quantity available to sell:</strong> {selectedBook.book_available_quantity}</p>
          <p><strong>Book Price:</strong> {selectedBook.book_price}</p>
          <p><strong>Book Ship from address location:</strong> {selectedBook.book_shipping_address}</p>
         
         
         
          <div>
            <strong>Book Pictures:</strong>
            <div className="grid grid-cols-2 gap-2">
              {selectedBook.book_pictures.map((image, index) => (
                <div key={index}>
                  <img
                    src={`https://oizvehadar.com/api/${image}`}
                    alt={`Book Image ${index + 1}`}
                    className="w-32 h-32 object-cover rounded-md"
                  />
                </div>
              ))}
            </div>
          </div>
          <div>
            <strong>PDF:</strong>
            <a
              href={`https://oizvehadar.com/api/${selectedBook.pdf_file}`}
              className="text-blue-600 hover:text-blue-800"
              target="_blank"
              rel="noopener noreferrer"
            >
              View PDF
            </a>
          </div>
          <h3 className="text-2xl font-bold mb-4 text-center">Personal Information </h3>
          <p><strong>Name:</strong> {selectedBook.name}</p>
          
          <p><strong>Email:</strong> {selectedBook.email}</p>
          <p><strong>Phone:</strong> {selectedBook.phone}</p>
          <p><strong>Address:</strong> {selectedBook.address}</p>
        </div>
        <button
          onClick={handleCloseView}
          className="mt-4 w-full bg-red-500 text-white py-2 rounded-md"
        >
          Close
        </button>
      </div>
    </div>
  )}
</div>

  );
};

export default UserBookList;
