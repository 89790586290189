import React, { useState, useEffect } from "react";
import { FaEdit, FaTrash, FaPlus } from "react-icons/fa";
import axios from "axios";
import BASE_URL from "../constant";
import LoadingPopup from "../Pages/LoadingPopup";
import htmlToPlainText from "../htmltotext";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const AddBundle = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isActive, setIsActive] = useState(true);

  const toggleStatus = async (id) => {
    setBundles((prevBundles) =>
      prevBundles.map((bundle) =>
        bundle.id === id
          ? { ...bundle, status: bundle.status === "active" ? "inactive" : "active" }
          : bundle
      )
    );
  
    setIsLoading(true);
  
    try {
      const params = new URLSearchParams();
  
      // Append the new status value (active or inactive)
      const updatedStatus = bundles.find(bundle => bundle.id === id).status === "active" ? "inactive" : "active";
      params.append("status", updatedStatus); 
  
      // Make the PUT request with the updated status
      const response = await axios.put(
        `${BASE_URL}/api_book.php/bundlebooks?id=${id}`,
        params,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
  
      console.log(response.data);
      setIsLoading(false);
      return response.data;
    } catch (error) {
      console.error("Error updating bundle:", error);
      setIsLoading(false);
      throw error;
    }
  };
  

  useEffect(() => {
    fetchBooks();
    fetchBundles();
  }, []);
  const addBundles = async (book) => {
    setIsLoading(true);
    try {
      console.log(book);
      const bookIds = book.books.map((book) => book.id);
      const response = await axios.post(
        `${BASE_URL}/api_book.php/bundlebooks`,
        {
          ...book,
          percentage: book.discount,
          bundle_name: book.name,
          book_ids: bookIds,
          price: book.price,
          price_is: book.price_is,
         end_at: book.end_at,
        }
      );
      console.log(response.data);
      setIsLoading(false);
      return response.data;
    } catch (error) {
      setIsLoading(false);
      console.error("Error adding bundle:", error);
      throw error;
    }
  };
  const RemoveBundles = async (id) => {
    try {
      const response = await axios.delete(
        `${BASE_URL}/api_book.php/bundlebooks?id=${id}`
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      console.error("Error adding bundle:", error);
      throw error;
    }
  };
  const updateBundles = async (book) => {
    setIsLoading(true);
    try {
      console.log(book);

      const params = new URLSearchParams();

      params.append("bundle_name", book.name);

      params.append("percentage", book.discount);

      book.books.forEach((book) => {
        params.append("book_ids[]", book.id);
      });

      params.append("price", book.price);
      params.append("price_is", book.price_is);
      params.append("end_at", book.end_at);

      const response = await axios.put(
        `${BASE_URL}/api_book.php/bundlebooks?id=${book.id}`,
        params,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      console.log(response.data);
      setIsLoading(false);
      return response.data;
    } catch (error) {
      console.error("Error adding bundle:", error);
      setIsLoading(false);
      throw error;
    }
  };

  const fetchBooks = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api_book.php/books`);
      setBooks(response.data);
    } catch (error) {
      console.error("Error fetching bundles:", error);
    }
  };

  const fetchBundles = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api_book.php/bundlebooks`);
      setBundles(response.data);
    } catch (error) {
      console.error("Error fetching bundles:", error);
    }
  };

  const [bundles, setBundles] = useState([]);
  const [Books, setBooks] = useState([]);
  const [editingBundle, setEditingBundle] = useState(null);
  const [addingBundle, setAddingBundle] = useState(false);
  const [newBundle, setNewBundle] = useState({
    name: "",
    discount: "",
    price: "",
    numberOfBooks: 0,
    books: [],
  });

  const handleDelete = (id) => {
    RemoveBundles(id);
    console.log(id);
    setBundles(bundles.filter((bundle) => bundle.id !== id));
  };

  const handleEdit = (bundle) => {
    console.log(bundle);
    setEditingBundle({
      ...bundle,
      name: bundle.bundle_name,
      discount: bundle.percentage,
    });
  };

  const handleSave = (updatedBundle) => {
    updateBundles(updatedBundle);
    setBundles(
      bundles.map((bundle) =>
        bundle.id === updatedBundle.id
          ? { ...updatedBundle, percentage: updatedBundle.discount }
          : bundle
      )
    );
    setEditingBundle(null);
  };

  const handleCloseModal = () => {
    setEditingBundle(null);
    setAddingBundle(false);
  };

  const handleAddBundle = () => {
    setAddingBundle(true);
  };

  const handleChangeNewBundle = (e) => {
    const { name, value } = e.target;
    setNewBundle((prevBundle) => ({
      ...prevBundle,
      [name]: value,
    }));
  };

  const handleSaveNewBundle = () => {
    const newId = bundles.length + 1;
    addBundles(newBundle);
    const bookIds = newBundle.books.map((book) => book.id);
    setBundles([
      ...bundles,
      {
        ...newBundle,
        id: newId,
        numberOfBooks: newBundle.books.length,
        percentage: newBundle.discount,
        bundle_name: newBundle.name,
        book_ids: bookIds,
      },
    ]);
    setNewBundle({
      name: "",
      discount: "",
      price: "",
      price_is: "",
      numberOfBooks: 0,
      books: [],
    });
    // fetchBundles();
    setAddingBundle(false);
  };

  return (
    <div className="w-full">
      <div className="flex justify-end mb-4">
        <button
          className="bg-[#6D3815] hover:bg-black text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          onClick={handleAddBundle}
        >
          <FaPlus className="mr-2" />
          Add Bundle
        </button>
      </div>
      <table className="w-full table-auto">
        <thead>
          <tr className="bg-[#fde5c3c4] text-left">
            <th className="px-4 py-2">Bundle Name</th>
            <th className="px-4 py-2">Discount</th>
            <th className="px-4 py-2">Price</th>
            <th className="px-4 py-2">Ending Date</th>
            <th className="px-4 py-2">Status</th>
            <th className="px-4 py-2">Actions</th>
            <th className="px-4 py-2">Change Status</th>
          </tr>
        </thead>
        <tbody>
          {bundles.map((bundle) => (
            <tr key={bundle.id}>
              <td className="border px-4 py-2">{bundle.bundle_name}</td>
              <td className="border px-4 py-2">{bundle.percentage} %</td>
              <td className="border px-4 py-2">$ {bundle.price}</td>
              <td className="border px-4 py-2">{bundle.end_at??"None"}</td>
              <td className="border px-4 py-2">{bundle.status}</td>
              <td className="border px-4 py-2">
                <button
                  className="mr-4"
                  onClick={() => {
                    handleEdit(bundle);
                  }}
                >
                  <FaEdit />
                </button>
                <button
                  className="ml-2"
                  onClick={() => handleDelete(bundle.id)}
                >
                  <FaTrash />
                </button>
              </td>
              <td className="border px-4 py-2">
              <div className="toggle-switch" onClick={() => toggleStatus(bundle.id)}>
                  <div
                    className={`switch ${bundle.status === "active" ? "active" : "inactive"}`}
                  />
                </div>
    </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Edit Bundle Modal */}
      {editingBundle && (
        <EditBundleModal
          bundle={editingBundle}
          onSave={handleSave}
          onClose={handleCloseModal}
          books={Books}
        />
      )}

      {/* Add Bundle Modal */}
      {addingBundle && (
        <AddBundleModal
          newBundle={newBundle}
          onChange={handleChangeNewBundle}
          onSave={handleSaveNewBundle}
          onClose={handleCloseModal}
          books={Books}
          selectedBooks={newBundle.books}
          handleCheckboxChange={(bookId) => {
            const selectedBook = Books.find((book) => book.id === bookId);
            setNewBundle((prevBundle) => ({
              ...prevBundle,
              books: prevBundle.books.some((book) => book.id === bookId)
                ? prevBundle.books.filter((book) => book.id !== bookId)
                : [...prevBundle.books, selectedBook],
            }));
          }}
          setNewBundle={setNewBundle}
        />
      )}
      {isLoading && <LoadingPopup />}
    </div>
  );
};

const AddBundleModal = ({
  newBundle,
  onChange,
  onSave,
  onClose,
  books,
  selectedBooks,
  handleCheckboxChange,
  setNewBundle,
}) => {
  const [showAddBookDropdown, setShowAddBookDropdown] = useState(false);

  return (
    <div className="fixed top-0 left-0 w-full h-full w-full flex justify-center items-center bg-gray-500 bg-opacity-50">
      <div className="bg-white w-[80%] p-8 rounded shadow-md">
        <h2 className="text-lg font-semibold mb-4">Add Bundle</h2>
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="name"
          >
            Bundle Name
          </label>
          <input
            className="border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="name"
            type="text"
            name="name"
            value={newBundle.name}
            onChange={onChange}
          />
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="discount"
          >
            Discount
          </label>
          <input
            className="border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="discount"
            type="number"
            name="discount"
            value={newBundle.discount}
            onChange={onChange}
          />
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="price"
          >
            Price
          </label>
          <input
            className="border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="price"
            type="number"
            name="price"
            value={newBundle.price}
            onChange={onChange}
          />
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="price"
          >
            Price in Israel
          </label>
          <input
            className="border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="price_is"
            type="number"
            name="price_is"
            value={newBundle.price_is}
            onChange={onChange}
          />
        </div>
        <label
          className="block text-gray-700 text-sm font-bold mb-2"
          htmlFor="price"
        >
          Select an End Date or Leave it Empty if you don’t want the Bundle to
          Expire
        </label>

        <DatePicker
          selected={newBundle.end_at ? new Date(newBundle.end_at) : null}
          id="end_at"
          onChange={(date) =>
            setNewBundle((prevBundle) => ({
              ...prevBundle,
              end_at: date ? date.toISOString().split("T")[0] : null,
            }))
          } // Updates the selected date
          dateFormat="yyyy-MM-dd" // Format of the date
          placeholderText="Choose a date" // Placeholder for empty input
          className="border rounded px-4 py-2" // Add custom styles
          isClearable // Adds a clear button to reset the field
        />

        <div className="mt-4 mb-4">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            onClick={() => setShowAddBookDropdown(!showAddBookDropdown)}
          >
            Add Books
          </button>
          {showAddBookDropdown && (
            <div className="mt-2">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="books"
              >
                Select Books
              </label>

              <div className="border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline overflow-y-auto max-h-64">
                {books.map((book) => (
                  <div key={book.id} className="flex items-center mb-2">
                    <input
                      type="checkbox"
                      id={`book-${book.id}`}
                      value={book.id}
                      checked={selectedBooks.some(
                        (selectedBook) => selectedBook.id === book.id
                      )}
                      onChange={() => handleCheckboxChange(book.id)}
                      className="mr-2"
                    />
                    <label
                      htmlFor={`book-${book.id}`}
                      className="text-gray-700"
                    >
                      {htmlToPlainText(book.title)}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        <div className="flex justify-end">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mr-2"
            onClick={onSave}
          >
            Save
          </button>
          <button
            className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            onClick={onClose}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

const EditBundleModal = ({ bundle, onSave, onClose, books }) => {
  const [editedBundle, setEditedBundle] = useState({ ...bundle });
  const [showEditBookDropdown, setShowEditBookDropdown] = useState(false);
  // const [selectedDate, setSelectedDate] = useState(null);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedBundle((prevBundle) => ({
      ...prevBundle,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (bookId) => {
    const selectedBook = books.find((book) => book.id === bookId);
    setEditedBundle((prevBundle) => ({
      ...prevBundle,
      books: prevBundle.books.some((book) => book.id === bookId)
        ? prevBundle.books.filter((book) => book.id !== bookId)
        : [...prevBundle.books, selectedBook],
    }));
  };

  const handleSave = () => {
    onSave({ ...editedBundle, numberOfBooks: editedBundle.books.length });
    onClose();
  };

  return (
    <div className="fixed top-0 left-0 w-full h-full w-full flex justify-center items-center bg-gray-500 bg-opacity-50">
      <div className="bg-white w-[80%] p-8 rounded shadow-md">
        <h2 className="text-lg font-semibold mb-4">Edit Bundle</h2>
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="name"
          >
            Bundle Name
          </label>
          <input
            className="border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="name"
            type="text"
            name="name"
            value={editedBundle.name}
            onChange={handleChange}
          />
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="discount"
          >
            Discount
          </label>
          <input
            className="border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="discount"
            type="number"
            name="discount"
            value={editedBundle.discount}
            onChange={handleChange}
          />
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="price"
          >
            Price
          </label>
          <input
            className="border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="price"
            type="number"
            name="price"
            value={editedBundle.price}
            onChange={handleChange}
          />
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="price"
          >
            Price in Israel
          </label>
          <input
            className="border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="price_is"
            type="number"
            name="price_is"
            value={editedBundle.price_is}
            onChange={handleChange}
          />
        </div>
        <label
          className="block text-gray-700 text-sm font-bold mb-2"
          htmlFor="price"
        >
          Select an End Date or Leave it Empty if you don’t want the Bundle to
          Expire
        </label>

        <DatePicker
          selected={editedBundle.end_at ? new Date(editedBundle.end_at) : null}
          id="end_at"
          onChange={(date) =>
            setEditedBundle((prevBundle) => ({
              ...prevBundle,
              end_at: date ? date.toISOString().split("T")[0] : null,
            }))
          }
          dateFormat="yyyy-MM-dd"
          placeholderText="Choose a date"
          className="border rounded px-4 py-2"
          isClearable
        />
        <div className="mt-4 mb-4">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            onClick={() => setShowEditBookDropdown(!showEditBookDropdown)}
          >
            Edit Books
          </button>
          {showEditBookDropdown && (
            <div className="mt-2">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="books"
              >
                Select Books
              </label>
              <div className="border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline overflow-y-auto max-h-64">
                {books.map((book) => (
                  <div key={book.id} className="flex items-center mb-2">
                    <input
                      type="checkbox"
                      id={`book-${book.id}`}
                      value={book.id}
                      checked={editedBundle.books.some(
                        (selectedBook) => selectedBook.id === book.id
                      )}
                      onChange={() => handleCheckboxChange(book.id)}
                      className="mr-2"
                    />
                    <label
                      htmlFor={`book-${book.id}`}
                      className="text-gray-700"
                    >
                      {htmlToPlainText(book.title)}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        <div className="flex justify-end">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mr-2"
            onClick={handleSave}
          >
            Save
          </button>
          <button
            className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            onClick={onClose}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddBundle;
