import React, { useState, useEffect } from "react";
import { BsPencilSquare, BsTrash, BsPlusCircle } from "react-icons/bs";
import BASE_URL from "../constant";
import axios from "axios";
import LoadingPopup from "../Pages/LoadingPopup";

// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { BsPencilSquare, BsTrash } from "react-icons/bs";
// import BASE_URL from "../constant";
// import LoadingPopup from "../Pages/LoadingPopup";

const AddCat = () => {
  const [categories, setCategories] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [newCategory, setNewCategory] = useState({ name: "", description: "" });
  const [isEdit, setIsEdit] = useState(false);
  const [editId, setEditId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api_category_catalog.php`);
      setCategories(response.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      console.log(id);
      const response = await axios.delete(`${BASE_URL}/api_category_catalog.php/?id=${id}`);
      console.log(response.data);
      if (response.data.message === "Record deleted successfully") {
        setCategories(categories.filter((cat) => cat.id !== id));
      }
    } catch (error) {
      console.error("Error deleting category:", error);
    }
  };

  const handleSave = async () => {
    setIsLoading(true);
    try {
      console.log("try");
      if (isEdit) {
        console.log("edit");
        const response = await axios.put(`${BASE_URL}/api_category_catalog.php/?id=${editId}`, {
          ...newCategory,
         id: editId,
        });
        console.log(response.data);
        if (response.data.message === "Record updated successfully") {
          setCategories(
            categories.map((cat) =>
              cat.id === editId ? { ...cat, ...newCategory } : cat
            )
          );
        }
      } else {
        const response = await axios.post(`${BASE_URL}/api_category_catalog.php`, newCategory);
        if (response.data.id) {
          setCategories([...categories, { id: response.data.id, ...newCategory }]);
        }
      }
      setShowModal(false);
      setNewCategory({ name: "", description: "" });
      setIsEdit(false);
      setEditId(null);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error saving category:", error);
    }
  };

  const handleEdit = (category) => {
    setNewCategory({ name: category.name, description: category.description });
    setIsEdit(true);
    console.log(category.id);
    setEditId(category.id);
    setShowModal(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewCategory({ ...newCategory, [name]: value });
  };

  const CategoryRow = ({ category }) => (
    <tr >
      <td className="p-2">{category.name}</td>
      
      <td className="p-2">
        <button onClick={() => handleEdit(category)}>
          <BsPencilSquare />
        </button>
      </td>
      <td className="p-2">
        <button onClick={() => handleDelete(category.id)}>
          <BsTrash />
        </button>
      </td>
    </tr>
  );

  return (
    <>
      <div className="w-[90%] m-auto mt-10">
        <div className="flex justify-between mb-4">
       
          <h2 className="text-2xl font-semibold">Catalog Category List</h2>
          <button
            onClick={() => setShowModal(true)}
            className="bg-[#6D3815] hover:bg-black text-white font-bold py-2 px-4 rounded"
          >
            Add New Category
          </button>
        </div>
        {/* Modal */}
        {showModal && (
          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
              </div>

              <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                &#8203;
              </span>

              <div
                className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
                role="dialog"
                aria-modal="true"
                aria-labelledby="modal-headline"
              >
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start w-full">
                    <div className="mt-3 text-center w-full sm:mt-0 sm:ml-4 sm:text-left">
                      <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                        {isEdit ? "Edit Category" : "Add New Category"}
                      </h3>
                      <div className="mt-2">
                        <div>
                          <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                            Name
                          </label>
                          <div className="mt-1 form-in">
                            <input
                              type="text"
                              name="name"
                              id="name"
                              value={newCategory.name}
                              onChange={handleChange}
                              className="shadow-sm p-4 focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-800 rounded-md"
                              placeholder="Category Name"
                            />
                          </div>
                        </div>
                        <div className="mt-3">
                          <label htmlFor="description" className="block text-sm font-medium text-gray-700">
                            Description
                          </label>
                          <div className="mt-1 form-in">
                            <textarea
                              id="description"
                              name="description"
                              value={newCategory.description}
                              onChange={handleChange}
                              rows={3}
                              className="shadow-sm p-4 focus:ring-blue-500 focus:border-blue-500 block w-full  sm:text-sm border-gray-800 rounded-md"
                              placeholder="Category Description"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={handleSave}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => {
                      setShowModal(false);
                      setNewCategory({ name: "", description: "" });
                      setIsEdit(false);
                      setEditId(null);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Table */}
        <div className="max-h-[250px] overflow-y-auto">
        <table className="w-full">
          <thead className="bg-[#fde5c3c4]  text-left">
            <tr className="">
              <th scope="col" className="py-4">
                Name
              </th>
            
              <th scope="col" className="py-4">
                <span >Edit</span>
              </th>
              <th scope="col" className="py-4">
                <span>Delete</span>
              </th>
            </tr>
          </thead>
          <tbody className="bg-white border divide-y divide-gray-200">
            {categories.map((category) => (
              <CategoryRow key={category.id} category={category} />
            ))}
          </tbody>
        </table>
        </div>
      </div>
      {isLoading && <LoadingPopup />}
    </>
  );
};




const EditModal = ({ showModal, setShowModal, selectedItem, handleEdit, setIsLoading, fetchCatalog }) => {
  const [formData, setFormData] = useState({
    title: selectedItem?.title || "",
    tags: selectedItem?.tags || "",
  });
  const [documentFile, setDocumentFile] = useState(null);
  const [coverImage, setCoverImage] = useState(null);
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState(selectedItem?.categories || []);

  // Fetch categories
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api_category_catalog.php`);
        setCategories(response.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleFileChange = (e) => {
    const { id, files } = e.target;
    if (id === "document") setDocumentFile(files[0]);
    else if (id === "Book_cover") setCoverImage(files[0]);
  };

  const handleCategoryChange = (e) => {
    const { value, checked } = e.target;
    setSelectedCategories((prev) =>
      checked ? [...prev, value] : prev.filter((category) => category !== value)
    );
    console.log(selectedCategories);
  };

  const addCatalog = async () => {
    setIsLoading(true);
    try {
      const url = `${BASE_URL}/api_catelog.php`;
      const data = new FormData();

      data.append("title", formData.title);
      data.append("categories", JSON.stringify(selectedCategories));
      if (documentFile) data.append("file", documentFile);
      if (coverImage) data.append("image", coverImage);

      await axios.post(url, data, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      setIsLoading(false);
      setShowModal(false);
      fetchCatalog();
    } catch (error) {
      setIsLoading(false);
      console.error("Error adding catalog:", error);
    }
  };

  const handleSubmit = () => {
    if (selectedItem) {
      handleEdit({ ...formData, categories: selectedCategories });
    } else {
      addCatalog();
    }
  };

  return (
    showModal && (
      <div className="fixed z-10 inset-0 overflow-y-auto">
        <div className="flex items-center justify-center min-h-screen px-4 text-center">
          <div className="bg-white rounded-lg shadow-lg w-full max-w-lg p-6">
            <h3 className="text-lg font-semibold">
              {selectedItem ? "Edit Item" : "Add New Catalog"}
            </h3>
            <div className="mt-4">
              <label className="block text-sm font-medium">Title</label>
              <input
                type="text"
                name="title"
                value={formData.title}
                onChange={handleChange}
                className="w-full p-2 border rounded mt-1"
              />
              <div className="mt-4">
                <label className="block text-sm font-medium">Categories</label>
                <div className="max-h-48 overflow-y-auto mt-2"> {/* Set max height and enable scrolling */}
  <div className="flex flex-wrap gap-2">
    {categories.map((category) => (
      <label key={category.id} className="flex items-center gap-1">
        <input
          type="checkbox"
          value={category.name}
          checked={selectedCategories.includes(category.name)}
          onChange={handleCategoryChange}
        />
        {category.name}
      </label>
    ))}
  </div>
</div>

              </div>
              <div className="mt-4">
                <label className="block text-sm font-medium">File Upload</label>
                <input type="file" id="document" onChange={handleFileChange} className="mt-1" />
              </div>
              <div className="mt-4">
                <label className="block text-sm font-medium">Upload Book Cover</label>
                <input type="file" id="Book_cover" onChange={handleFileChange} className="mt-1" />
              </div>
            </div>
            <div className="mt-6 flex justify-end gap-2">
              <button
                onClick={handleSubmit}
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
              >
                Save
              </button>
              <button
                onClick={() => setShowModal(false)}
                className="bg-gray-300 px-4 py-2 rounded hover:bg-gray-400"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  );
};






const Catalog = () => {
  const [initialData,setIData] = useState([
  ]);
  const [data, setData] = useState(initialData);
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    fetchCatalog();
  },[]);

  const  fetchCatalog = async () => {
    try {
 
      const response = await axios.get(`${BASE_URL}/api_catelog.php`);
      const bookData = response.data;
      console.log(response.data);
     setIData(response.data.catalog);
    console.log(initialData);    
    
     
    } catch (error) {

  
      console.error('Error fetching Catalog:', error);
    }
  };

 
  const handleEdit = (item) => {
    if (item.id) {
      // Edit existing item
      setData((prevData) =>
        prevData.map((dataItem) =>
          dataItem.id === item.id ? item : dataItem
        )
      );
    } else {
      // Add new item with unique ID
      setData((prevData) => [
        ...prevData,
        { ...item, id: prevData.length + 1 },
      ]);
    }
  };

  const handleOpenModal = (item = null) => {
    setSelectedItem(item);
    setShowModal(true);
  };
  const deleteCatalog=async (id)=>{
    try {
 
      const response = await axios.delete(`${BASE_URL}/api_catelog.php?id=${id}`);
      const bookData = response.data;
      console.log(response.data);
      setIData(initialData.filter((i) => i.id !== id));
      fetchCatalog();
    console.log(initialData);    
    
     
    } catch (error) {

       

     
     
     
      console.error('Error fetching Catalog:', error);
    }
  };

  return (
    <>
      <div className="w-[90%] m-auto mt-10">
      <br/>
      <AddCat/>
      <br/>
      <br/>
        <div className="flex justify-between items-center mb-4">
        
          <h2 className="text-2xl font-semibold">Catalog List</h2>
          <button
            className="bg-red-900 text-white px-4 py-2 rounded-md hover:bg-green-700 flex items-center gap-2"
            onClick={() => handleOpenModal()}
          >
            <BsPlusCircle /> Add New Catalog
          </button>
        </div>
        <div className="max-h-[450px] overflow-y-auto">
          <table className="w-full">
            <thead className="bg-[#fde5c3c4] text-left">
              <tr>
                <th className="py-4">Title</th>
                {/* <th className="py-4">Tags</th> */}
                <th className="py-4">Actions</th>
              </tr>
            </thead>
            <tbody className="bg-white border divide-y divide-gray-200">
              {initialData.map((item) => (
                <tr key={item.id}>
                  <td className="p-2">{item.title}</td>
                  {/* //<td className="p-2">{item.tags}</td> */}
                  <td className="p-2 flex gap-2">
                    {/* <button onClick={() => handleOpenModal(item)}>
                      <BsPencilSquare />
                    </button> */}
                    <button onClick={()=>{
                      deleteCatalog(item.id);
                    }}>
                      <BsTrash />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {isLoading && <LoadingPopup />}
      {showModal && (
        <EditModal
          showModal={showModal}
          setShowModal={setShowModal}
          selectedItem={selectedItem}
          handleEdit={handleEdit}
          setIsLoading={setIsLoading}
          fetchCatalog={fetchCatalog}

        />
      )}
    </>
  );
};

export default Catalog;
