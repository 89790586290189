import React, { useEffect, useState } from "react";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import Select from "react-select";
import axios from "axios";
import BASE_URL from "../constant";
import LoadingPopup from "../Pages/LoadingPopup";
import { Markup } from "interweave";
import htmlToPlainText from "../htmltotext";
import { t } from "i18next";
import { FaEdit } from "react-icons/fa";

const AddVol = () => {
  const [type,setType]=useState(localStorage.getItem("type") || '');
  const [data, setData] = useState([]);
  const [selectedfiles, setSelectedFiles] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [volumes, setVolumes] = useState([]);
  const [books, setBookList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    console.log(file);
    setSelectedFile(file);
    if (selectedFile) {
      console.log("Selected File:", selectedFile);
    } else {
      console.log("No file selected");
    }
  };

  useEffect(() => {
    fetchVolumes();
    fetchBooks();
    fetchPdfs();
  }, []);
  const handlefileChange = (selectedOption) => {
    console.log(selectedOption);
    setSelectedFiles(selectedOption ? selectedOption.label : null);
  };
  const fetchPdfs = async () => {
    try {
      let url = `${BASE_URL}/add_files.php?type=searchable`;
      const response = await axios.get(url);
      const bookData = response.data.results;
      console.log(response.data.files);
      console.log(response.data.files);

      if (Array.isArray(response.data.files)) {
        // Create options for the dropdown
        const fileOptions = response.data.files.map((file, index) => ({
          value: index, // or any other unique identifier
          label: file,
        }));

        // Assuming you have a state setter for these options
        setData(fileOptions); // Replace with the appropriate state setter
      } else {
        console.error("Expected an array of pdfs");
      }
    } catch (error) {
      console.error("Error fetching pdfs:", error);
    }
  };
  const fetchBooks = async () => {
    try {
      let url = `${BASE_URL}/api_book.php/books`;
      const response = await axios.get(url);
      const bookData = response.data;
      console.log(response.data);
      if (Array.isArray(bookData)) {
        const bookOptions = bookData.map((book) => ({
          value: book.id,
          label: htmlToPlainText(book.title),
        }));
        setBookList(bookOptions);
      } else {
        console.error("Expected an array of books");
      }
    } catch (error) {
      console.error("Error fetching books:", error);
    }
  };

  const fetchVolumes = async () => {
    try {
      let url = `${BASE_URL}/api_volumes.php`;
      const response = await axios.get(url);
      const volumeData = response.data;
      console.log(response.data);
      if (Array.isArray(volumeData)) {
        setVolumes(volumeData);
      } else {
        console.error("Expected an array of volumes");
      }
    } catch (error) {
      console.error("Error fetching volumes:", error);
    }
  };

  const handleBookChange = (selectedOption) => {
    setSelectedBook(selectedOption ? selectedOption.value : null);
  };

  // State for editing an existing volume
  const [editIndex, setEditIndex] = useState(null);
  const [editName, setEditName] = useState("");
  const [editBooks, setEditBooks] = useState("");
  const [editPrice, setEditPrice] = useState(0);
  const [editPriceIs, setEditPriceIs] = useState(0);
  const [editQuantity, setEditQuantity] = useState(0);
  const [editQuantityIs, setEditQuantityIs] = useState(0);
  const [editVolumeMaterial, setEditVolumeMaterial] = useState("");
  const [editMustBuy, setEditMustBuy] = useState(false);
  const [editSKU, seteditSKU] = useState('');
  

  // State for adding a new volume
  const [showAddModal, setShowAddModal] = useState(false);
  const [newVolumeName, setNewVolumeName] = useState("");
  const [newVolumeMaterial, setNewVolumeMaterial] = useState("");
  const [newVolumePrice, setNewVolumePrice] = useState(0);
  const [newPriceIs, setNewPriceIs] = useState(0);
  const [newQuantity, setnewQuantity] = useState(0);
  const [newQuantityIs, setnewQuantityIs] = useState(0);
  const [selectedBook, setSelectedBook] = useState("");
  const [newMustBuy, setNewMustBuy] = useState(false);
  const [newSKU, setSKU] = useState('');
  

  // Function to open modal for editing an existing volume
  const handleEdit = (vol) => {
    setEditIndex(vol.id);
    setEditName(vol.title);
    setEditBooks(vol.book_id);
    setEditPrice(vol.price);
    setEditMustBuy(vol.must_buy != "1" ? false : true);
    setEditPriceIs(vol.price_is);
    setEditQuantity(vol.warehouse_quantity);
    setEditQuantityIs(vol.warehouse_quantity_is);
    setEditVolumeMaterial(vol.material);
    seteditSKU(vol.asin);
  };
  const handleDelete = async (id) => {
    try {
      const url = `${BASE_URL}/api_volumes.php?id=${id}`;
      await axios.delete(url);
      setVolumes(volumes.filter((book) => book.id !== id));
    } catch (error) {
      console.error("Error deleting book:", error);
    }
  };
  // Function to close modal and save edited data
  const handleCloseEditModal = async () => {
    if (editIndex !== null) {
      const formData = new FormData();
      // formData.append("title", editName);
      // formData.append("book_id", editBooks);
      formData.append("price", editPrice);
      formData.append("price_is", editPriceIs);
      formData.append("warehouse_quantity", editQuantity);
      formData.append("warehouse_quantity_is", editQuantityIs);
      // formData.append("material", editVolumeMaterial);
      // formData.append("must_buy",editMustBuy?"1":"0");
      // formData.append("pdf_location", selectedfiles);
      // formData.append('asin',editSKU);
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      const updatedVolume = {
        id: editIndex,
        title: editName,
        book_id: editBooks,
        price: editPrice,
        material: editVolumeMaterial,
        pdf_location: selectedfiles,
        asin:editSKU
      };
      try {
        setIsLoading(true);
        const response = await axios.post(
          `${BASE_URL}/api_volumes.php?id=${editIndex}`,
          formData,
          config
        );
        if (response.status === 200) {
          const selectedBook = books.find((book) => book.value === editBooks);
          const updatedVolumes = volumes.map((vol) =>
            vol.id === updatedVolume.id
              ? { ...updatedVolume, book: selectedBook.label }
              : vol
          );
          setVolumes(updatedVolumes);
          fetchVolumes();
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        console.error("Error updating volume:", error);
      }
    }
    resetEditState();
  };

  // Function to reset edit state and close modal
  const resetEditState = () => {
    setEditIndex(null);
    setEditName("");
    setEditBooks("");
    setEditPrice(0);
    setEditVolumeMaterial("");
  };

  // Function to open modal for adding a new volume
  const openAddModal = () => {
    setShowAddModal(true);
    setNewVolumeName("");
    setSelectedBook(null);
    setNewVolumeMaterial("");
    setNewVolumePrice(0);
  };

  // Function to close modal for adding a new volume
  const handleCloseAddModal = () => {
    setShowAddModal(false);
    setNewVolumeName("");
    setSelectedBook(null);
    setNewVolumeMaterial("");
    setNewVolumePrice(0);
  };

  const handleAddVolume = async () => {
    if (selectedBook) {
      const formData = new FormData();
      formData.append("title", newVolumeName);
      formData.append("book_id", selectedBook);
      formData.append("price", newVolumePrice);
      formData.append("price_is", newPriceIs);
      formData.append("warehouse_quantity", newQuantity);
      formData.append("warehouse_quantity_is", newQuantityIs);
      formData.append("material", newVolumeMaterial);
      formData.append("pdf_location", selectedfiles);
      formData.append("must_buy",newMustBuy?"1":"0");
      formData.append('asin',newSKU);
      // const newVolume = {
      //     title: newVolumeName,
      //     book_id: selectedBook,
      //     price: newVolumePrice,
      //     material: newVolumeMaterial
      // };
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      try {
        setIsLoading(true);
        const response = await axios.post(
          `${BASE_URL}/api_volumes.php`,
          formData,
          config
        );
        if (response.status === 201) {
          fetchVolumes();
          setShowAddModal(false); // Close modal after adding
          setNewVolumeName("");
          setSelectedBook(null);
          setNewVolumeMaterial("");
          setSKU('');
          setNewVolumePrice(0);
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        console.error("Error adding volume:", error);
      }
    }
  };

  return (
    <>
      <h2 className="text-2xl font-semibold">{t("vol_list")}</h2>
      {/* <button
        className="bg-[#6D3815] hover:bg-black text-white px-4 py-2 rounded mt-4"
        onClick={openAddModal}
      >
        Add Volume
      </button> */}
      <div className="overflow-y-auto max-h-[600px]">
        <table className="border-collapse w-full border border-gray-200 mt-4">
          <thead className="bg-[#fde5c3c4]  text-left">
            <tr>
              <th className="border border-gray-200 px-4 py-2">{t("name")}</th>
              <th className="border border-gray-200 px-4 py-2">{t("books")}</th>
              <th className="border border-gray-200 px-4 py-2">{t("qty")}</th>
              <th className="border border-gray-200 px-4 py-2">{t("price")}</th>
              <th className="border border-gray-200 px-4 py-2">{t("action")}</th>
            </tr>
          </thead>
          <tbody>
            {volumes.map((vol, index) => (
              <tr key={vol.id}>
                <td className="border border-gray-200 px-4 py-2">
                  {vol.title}
                </td>
                {/* <Markup content={book.title}  /> </td> */}
                <td className="border border-gray-200 px-4 py-2">
                  <Markup content={vol.book} />{" "}
                </td>
                <td className="border border-gray-200 px-4 py-2">
                  {type!=="israel-warehouse"?vol.warehouse_quantity:vol.warehouse_quantity_is}
                </td>
                <td className="border border-gray-200 px-4 py-2">
                {type!=="israel-warehouse"?vol.price:vol.price_is}
                </td>
                <td className="border border-gray-200 px-4 py-2">
                          <button
                    onClick={() => handleEdit(vol)}
                    className="bg-[#6D3815] w-full text-white flex justify-center items-center rounded px-2 py-2"
                  >
                    <FaEdit />
                  </button>
                  
                  {/* <button
                    className="text-blue-500 hover:text-blue-600 mr-2"
                    onClick={() => handleEdit(vol)} // Open modal for editing
                  > */}
                    {/* <AiOutlineEdit className="inline-block" />
                  </button> */}
                 
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* Modal for Editing an Existing Volume */}
      {editIndex !== null && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white w-[80%] p-8 rounded-lg shadow-lg">
            <h2 className="text-lg font-bold mb-4">{t("edit_volume")}</h2>
         
            {type!=="israel-warehouse"?(<> <div className="flex space-x-4 mb-4">
              <div className="w-1/2">
                <label className="block text-sm font-medium text-gray-700">
                {t("price_us")}
                </label>
                <input
                  type="number"
                  step="0.01"
                  className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
                  value={editPrice}
                  onChange={(e) => setEditPrice(parseFloat(e.target.value))}
                />
              </div>
             
            </div>
            <div className="flex space-x-4 mb-4">
              <div className="w-1/2">
                <label className="block text-sm font-medium text-gray-700">
                {t("choose_qty")}
                </label>
                <input
                  type="number"
                  //   step="0.01"
                  className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
                  value={editQuantity}
                  onChange={(e) => setEditQuantity(e.target.value)}
                />
              </div>
             
            </div></>):(<><div className="flex space-x-4 mb-4">
             
              <div className="w-1/2">
                <label className="block text-sm font-medium text-gray-700">
                {t("price_is")}
                </label>
                <input
                  type="number"
                  step="0.01"
                  className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
                  value={editPriceIs}
                  onChange={(e) => setEditPriceIs(parseFloat(e.target.value))}
                />
              </div>
            </div>
            <div className="flex space-x-4 mb-4">
             
              <div className="w-1/2">
                <label className="block text-sm font-medium text-gray-700">
                {t("choose_qty_is")}
                </label>
                <input
                  type="number"
                  //   step="0.01"
                  className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
                  value={editQuantityIs}
                  onChange={(e) => setEditQuantityIs(e.target.value)}
                />
              </div>
            </div></>)}
           

          
            <button
              className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded"
              onClick={handleCloseEditModal}
            >
             {t("save_ch")}
            </button>
            <button
              className="bg-gray-300 hover:bg-gray-400 text-gray-800 px-4 py-2 ml-2 rounded"
              onClick={resetEditState}
            >
                {t("close")}
            </button>
          </div>
        </div>
      )}

      {/* Modal for Adding a New Volume */}
   
      {isLoading && <LoadingPopup />}
    </>
  );
};

export default AddVol;
